import { template as template_7fc952915b8a4d43b7f2c9eb8c94b63c } from "@ember/template-compiler";
const WelcomeHeader = template_7fc952915b8a4d43b7f2c9eb8c94b63c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
