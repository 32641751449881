import { template as template_e44af9a66941402a8b0dfc9a62d2b9af } from "@ember/template-compiler";
const FKText = template_e44af9a66941402a8b0dfc9a62d2b9af(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
