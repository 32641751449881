import { template as template_9f8a41c87f3248b1bb0151186c521dd0 } from "@ember/template-compiler";
import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
const SidebarSections = template_9f8a41c87f3248b1bb0151186c521dd0(`
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSections;
